import React from 'react';
import { useHistory } from 'react-router-dom';
import { paintathon, conan } from '../../constants/iconImageConstant';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';


export function EventPage() {
  const history = useHistory();
  const handleNavigate = (path: string) => {
    history.push(path);
  };

  return (
    <>
      <Header />
      <main role="main" className="container event-page-container">
        <div className="event-page-content">
          <div className="image-container">
            <div className="image-wrapper">
              <img
                src={paintathon}
                alt="paintathon"
                onClick={() => handleNavigate('/paintathon')}
                className="clickable-image"
              />
            </div>
            <div className="image-wrapper">
              <img
                src={conan}
                alt="conanImage"
                onClick={() => handleNavigate('/canonYears')}
                className="clickable-image"
              />
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
}
