import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL_CONSTANTS } from '../../constants/apiUrlConstants';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';

interface SessionDetail {
  id: string;
  name: string;
  image: string;
}

export function PainwinList_2024() {
  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;
  const [selectedYear, setSelectedYear] = useState('2024'); 
  const [response, setResponse] = useState<SessionDetail[]>([]);

  useEffect(() => {
    fetchPaintathonResult(selectedYear);
  }, [selectedYear]);

  const fetchPaintathonResult = async (year: string) => {
    try {
      const res = await axios.post(
        url,
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (Array.isArray(res.data)) {
        setResponse(res.data);
      } else if (res.data && Array.isArray(res.data.results)) {
        setResponse(res.data.results);
      } else {
        setResponse([]);
        console.warn('Unexpected response format:', res.data);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  // Extract different groups from the response for 2024
  const megaWinner = response.slice(0, 1);
  const groupA = response.slice(1, 4);
  const groupB = response.slice(4, 7);
  const groupC = response.slice(7, 10);

  // Component to render image groups
  const ImageGroup = ({ title, group }: { title: string, group: SessionDetail[] }) => (
    <>
      <h3 className="mt-2 mb-2">{title}</h3>
      <div className="col-md-6 offset-3">
        <div className="row">
          {group.map(item => (
            <div key={item.id} className="col-md-4">
              <div className="gropbox">
                <img src={`https://assets.etvbalbharat.com/${item.image}`} alt={item.name} width="100%" />
                <h4>{item.name}</h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <div className="container-fluid page-bgs">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="pain-win-yrs">
              <div className="win-list-box">
                  <div className="win-tit">Winner List</div>
                  <div className="pain-yrs">
                    <Link to="/painthon_win_list_2021">2021</Link>
                  </div>
                  <div className="pain-yrs">
                    <Link to="/painthon_win_list_2022" >2022</Link>
                  </div>
                  <div className="pain-yrs">
                    <Link to="/painthon_win_list_2024">2024</Link>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <div className="pain-win-list">
                <div className="mega-win-1st">
                  {/* Mega Winner only for 2024 */}
                  {selectedYear === '2024' && megaWinner.length > 0 && (
                    <div>
                      <h3>Mega Winner</h3>
                      <div className="megawin">
                        <img src={`https://assets.etvbalbharat.com/${megaWinner[0].image}`} alt="group01" width="100%" />
                      </div>
                    </div>
                  )}

                  <ImageGroup title="Group A" group={groupA} />
                  <ImageGroup title="Group B" group={groupB} />
                  <ImageGroup title="Group C" group={groupC} />
                </div>
              </div>
            </div>
          </div>
        </div>
          <Footer />
      </div>
    
    </>
  );
}





































































// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { API_URL_CONSTANTS } from '../../constants/apiUrlConstants';
// import { Header } from '../Header/Header';
// import { Footer } from '../appFooter/Footer';
// // import './events-styles.css'; // Make sure your custom CSS is imported

// // Define the type for session details
// interface SessionDetail {
//   id: string;
//   name: string;
//   image: string;
// }

// export function PainwinList_2024() {
//   const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;
//   const [selectedYear, setSelectedYear] = useState(''); // Default empty
//   const [response, setResponse] = useState<SessionDetail[]>([]);

//   useEffect(() => {
//     if (selectedYear) {
//       fetchPaintathonResult(selectedYear);
//     }
//   }, [selectedYear]);

//   const fetchPaintathonResult = async (year: string) => {
//     try {
//       const res = await axios.post(
//         url,
//         { params: { year } },
//         { headers: { 'Content-Type': 'application/json' } }
//       );

//       if (Array.isArray(res.data)) {
//         setResponse(res.data);
//       } else if (res.data && Array.isArray(res.data.results)) {
//         setResponse(res.data.results);
//       } else {
//         setResponse([]);
//         console.warn('Unexpected response format:', res.data);
//       }
//     } catch (error) {
//       console.log('Error fetching data:', error);
//     }
//   };

//   const handleYearChange = (year: string) => {
//     setSelectedYear(year);
//   };

//   // Extract different groups from the response based on the selected year
//   const megaWinner = selectedYear === '2024' ? response.slice(0, 1) : []; // Mega Winner only for 2024
//   const groupA = selectedYear === '2021' || selectedYear === '2022' ? response.slice(0, 3) : response.slice(1, 4);
//   const groupB = selectedYear === '2021' || selectedYear === '2022' ? response.slice(3, 6) : response.slice(4, 7);
//   const groupC = selectedYear === '2024' ? response.slice(7, 10) : []; // Group C only for 2024

//   // Component to render image groups
//   const ImageGroup = ({ title, group }: { title: string, group: SessionDetail[] }) => (
//     <>
//       <h3 className="mt-2 mb-2">{title}</h3>
//       <div className="col-md-6 offset-3">
//         <div className="row">
//           {group.map(item => (
//             <div key={item.id} className="col-md-4">
//               <div className="gropbox">
//                 <img src={`https://assets.etvbalbharat.com/${item.image}`} alt={item.name} width="100%" />
//                 <h4>{item.name}</h4>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </>
//   );

//   return (
//     <>
//       <Header />
//       <div className="container-fluid page-bgs">
//         <div className="container">
//           <div className="row">
//             <div className="col-md-6">
//               <div className="pain-win-yrs">
//                 <div className="win-list-box">
//                   <div className="win-tit">Winner List</div>
//                   {['2021', '2022', '2024'].map(year => (
//                     <div className="pain-yrs" key={year}>
//                       <a href="#" onClick={() => handleYearChange(year)}>{year}</a>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>

//             <div className="col-md-6">
//               <div className="pain-win-list">
//                 <div className="mega-win-1st">
//                   {selectedYear === '2024' && megaWinner.length > 0 && (
//                     <div>
//                       <h3>Mega Winner</h3>
//                       <div className="megawin">
//                         <img src={`https://assets.etvbalbharat.com/${megaWinner[0].image}`} alt="group01" width="100%" />
//                       </div>
//                     </div>
//                   )}

//                   <ImageGroup title="Group A" group={groupA} />
//                   <ImageGroup title="Group B" group={groupB} />

//                   {selectedYear === '2024' && <ImageGroup title="Group C" group={groupC} />}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }
