import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL_CONSTANTS } from '../../../constants/apiUrlConstants';
import { 
    mob_paintathonList, 
    mob_paintathon_2021, 
    mob_paintathon_2022, 
    mob_paintathon_2024, 
    mob_pain_winner 
} from '../../../constants/iconImageConstant';
import { Header } from '../../Header/Header';

// Define the type for session details
interface SessionDetail {
  id: string;
  name: string;
  image: string;
}

export function MobPainwinList2021() {
  const [response, setResponse] = useState<SessionDetail[]>([]);
  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;

  useEffect(() => {
    fetchPaintathonResult('2021');
  },[]);

  const fetchPaintathonResult = async (year: string) => {
    try {
      const res = await axios.post(
        url, 
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );
      if (Array.isArray(res.data)) {
        setResponse(res.data);
      } else if (res.data && Array.isArray(res.data.results)) {
        setResponse(res.data.results);
      } else {
        setResponse([]);
        console.warn('Unexpected response format:', res.data);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };


  const groupA = response.slice(0, 3); 
  const groupB = response.slice(3, 6); 
  const groupC = response.slice(6, 9); 
 
  const ImageGroup = ({ title, group }: { title: string, group: SessionDetail[] }) => (
    <>
      <h1>{title}</h1>
      <div className="win-container">
        <ul>
          {group.map(item => (
            <li key={item.id}>
              <img src={`https://assets.etvbalbharat.com/${item.image}`} alt={item.name} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <div className="container-fluid mob-pain-winlist">
        <div className="col-md-12">
          <div className="mob-pain-winyrs">
            <img src={mob_paintathonList} alt="Paintathon" width="100%" />
            <div className="mob-pain-yrs">
              <ul>
                <li>
                  <Link to="#" onClick={() => fetchPaintathonResult('2021')}>
                    <img src={mob_paintathon_2021} alt="Paintathon 2021" />
                  </Link>
                </li>
                <li>
                  <Link to="/mob_painthon_win_list_2022">
                    <img src={mob_paintathon_2022} alt="Paintathon 2022" />
                  </Link>
                </li>
                <li>
                  <Link to="/mob_painthon_win_list_2024">
                    <img src={mob_paintathon_2024} alt="Paintathon 2024" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="mob-pain-yrs21">
            <div className="yer-2021">
              <img src={mob_pain_winner} alt="Paintathon 2021" width="100%" />
            </div>

            <div className="pain-winlist-box">
             
              {/* Group A */}
              {groupA.length > 0 && <ImageGroup title="Group A" group={groupA} />}

              {/* Group B */}
              {groupB.length > 0 && <ImageGroup title="Group B" group={groupB} />}

              {/* Group C */}
              {groupC.length > 0 && <ImageGroup title="Group C" group={groupC} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

