// added new code .............16/09/2024

import React, { useEffect, useState } from 'react';
import ReactBodymovin from 'react-bodymovin';
import animation from '../assets/loader.json';
import 'bootstrap/dist/css/bootstrap.min.css';

export const Loader: React.FC = () => {
  // State to control the loader display
  const [showLoader, setShowLoader] = useState<boolean>(true);

  const bodymovinOptions = {
    loop: true,
    autoplay: true,
    prerender: true,
    animationData: animation,
  };

  useEffect(() => {
    // Set timeout to hide the loader after 1 hour (3600000 milliseconds)
    const timer = setTimeout(() => {
      setShowLoader(false); // Hide loader after 1 hour
    }, 3600000); // 1 hour in milliseconds

    // Cleanup the timer when the component is unmounted
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {showLoader ? (
        <div className="loader-container">
          <ReactBodymovin options={bodymovinOptions}>
            <span className="sr-only">Loading animation...</span>
          </ReactBodymovin>
        </div>
      ) : null}
    </>
  );
};





// comment this code 16/09/2024
// import React from 'react';
// import ReactBodymovin from 'react-bodymovin';
// import animation from '../assets/loader.json';
// import 'bootstrap/dist/css/bootstrap.min.css';
 

// export const Loader = () => {
//   const bodymovinOptions = {
//     loop: true,
//     autoplay: true,
//     prerender: true,
//     animationData: animation,
// };

//   return (
//     <div className="loader-container">
//       <ReactBodymovin options={bodymovinOptions}>
//         <span className="sr-only">Loading animation...</span>
//       </ReactBodymovin>
//     </div>
//   );
// };
