import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_URL_CONSTANT } from '../../../constants/routerUrlConstant'; 
import { Header } from '../../Header/Header'; 
import { Footer } from '../../appFooter/Footer'; 
import { mob_conan_bg, conanbtn } from '../../../constants/iconImageConstant'; 

export function ConanYearMobile() {
  return (
    <>
      <Header />
      <div className="container-fluid mob-conanbg">
        <div className="col-md-12">
          <div className="mob-pain-nxtpg-cont">
            <img src={mob_conan_bg} alt="conan" />
            <div className="mob-conan-nxtgb">
              <Link to={ROUTER_URL_CONSTANT.MOB_CANON_WINNER_LIST}>
                <img src={conanbtn} alt="conan button" />
              </Link>
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
    <Footer />
    </>
  );
}
