import { Component } from 'react';
import axios from 'axios';
import { withRouter, RouteComponentProps } from 'react-router-dom';

interface Metadata {
  pages : string;
  title: string;
  keyword: string;
  desc: string;
}

interface Props extends RouteComponentProps {}

interface State {
  metadata: Metadata | null;
}

class MetadataUpdater extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      metadata: null,
    };
  }

  componentDidMount() {
    this.fetchMetadata();
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.fetchMetadata();
    }
  }

  fetchMetadata = async () => {
    try {
      const response = await axios.get('https://api.etvbalbharat.com/MVC/api/metadata');
      console.log('API response:', response.data); 
      const page = this.getPageName();
      console.log('Current page:', page); 
      const metadata = response.data.find((item: Metadata) => item.pages === page);

      console.log('Metadata found:', metadata); 

      if (metadata) {
        this.setState({ metadata }, () => {
          this.updateMetadata();
        });
      } else {
        this.setState({ metadata: null }, () => {
          this.updateMetadata();
        });
      }
    } catch (error) {
      console.error('Error fetching metadata:', error);
    }
  }

  getPageName = () => {
    const { pathname } = this.props.location;
    const page = pathname.split('/')[1];
    return page.charAt(0).toUpperCase() + page.slice(1) || 'Home';
  }

  updateMetadata = () => {
    const { metadata } = this.state;

    console.log('Updating metadata:', metadata); 

    document.title = metadata?.title || 'Etv bal bharat';

    this.updateOrCreateMetaTag('keywords', metadata?.keyword || 'Default Keywords');
    this.updateOrCreateMetaTag('description', metadata?.desc || 'Default Description');
  }

  updateOrCreateMetaTag = (name: string, content: string) => {
    let metaTag = document.querySelector(`meta[name="${name}"]`) as HTMLMetaElement;

    if (metaTag) {
      metaTag.setAttribute('content', content);
    } else {
      metaTag = document.createElement('meta');
      metaTag.name = name;
      metaTag.content = content;
      document.head.appendChild(metaTag);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(MetadataUpdater);
