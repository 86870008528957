import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { mob_paintathonList, mob_paintathon_2021, mob_paintathon_2022, mob_paintathon_2024 ,mob_win_24} from '../../../constants/iconImageConstant';
import { Header } from '../../Header/Header';
import { Footer } from '../../appFooter/Footer';
import { Link } from 'react-router-dom';
import { API_URL_CONSTANTS } from '../../../constants/apiUrlConstants';

interface WinnerDetail {
  id: string;
  name: string;
  image: string;
}

export function MobPainwinList2024() {
  const [response, setResponse] = useState<WinnerDetail[]>([]);
  const selectedYear = '2024'; // Hardcoded to 2024 for now
  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;

  useEffect(() => {
    fetchPaintathonResult(selectedYear);
  }, [selectedYear]);

  const fetchPaintathonResult = async (year: string) => {
    try {
      const res = await axios.post(
        url,
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (Array.isArray(res.data)) {
        setResponse(res.data);
      } else if (res.data && Array.isArray(res.data.results)) {
        setResponse(res.data.results);
      } else {
        setResponse([]);
        console.warn('Unexpected response format:', res.data);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  // Extract different groups from the response for 2024
  const megaWinner = response.slice(0, 1);
  const groupA = response.slice(1, 4);
  const groupB = response.slice(4, 7);
  const groupC = response.slice(7, 10);

  const ImageGroup = ({ title, group }: { title: string, group: WinnerDetail[] }) => (
    <>
      <h1>{title}</h1>
      <div className="mega-win-container">
        <ul>
          {group.map(item => (
            <li key={item.id}>
              <img src={`https://assets.etvbalbharat.com/${item.image}`} alt={item.name} />
              <h4>{item.name}</h4>
            </li>
          ))}
        </ul>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <div className="container-fluid mob-pain-winlist">
        <div className="row">
          <div className="col-md-12">
            <div className="mob-pain-winyrs">
              <img src={mob_paintathonList} alt="Paintathon" width="100%" />
              <div className="mob-pain-yrs">
                <ul>
                  <li><Link to="/painthon_win_list_2021"><img src={mob_paintathon_2021} alt="2021" /></Link></li>
                  <li><Link to="/painthon_win_list_2022"><img src={mob_paintathon_2022} alt="2022" /></Link></li>
                  <li><Link to="/painthon_win_list_2024"><img src={mob_paintathon_2024} alt="2024" /></Link></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="mob-pain-yrs21">
              <div className="yer-2024">
                <Link to="/painthon_win_list_2024">
                  <img src={mob_win_24} alt="Paintathon 2024 Background" width="100%" />
                </Link>
              </div>

              <div className="pain-winlist-box24">
                {megaWinner.length > 0 && (
                  <div>
                    <h1>Mega Winner</h1>
                    <div className="mega-win">
                      <img src={`https://assets.etvbalbharat.com/${megaWinner[0].image}`} alt={megaWinner[0].name} />
                      <h4>{megaWinner[0].name}</h4>
                    </div>
                  </div>
                )}

                <ImageGroup title="Group A" group={groupA} />
                <ImageGroup title="Group B" group={groupB} />
                <ImageGroup title="Group C" group={groupC} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}














































































// import React from 'react';
// import {mob_win_24,mob_paintathonList, mob_paintathon_2021,mega_winner_24, mob_pain_winner, mob_paintathon_2022,
//   mob_paintathon_2024,winnerImage_2,winnerImage_4,groupImage1,groupImage_2,groupImage_3,winnerImage
// } from '../../../constants/iconImageConstant';
// import { Header } from '../../Header/Header'; 
// import { Footer } from '../../appFooter/Footer'; 
// import { Link } from 'react-router-dom';

// export function MobPainwinList2024() {
//   return (
//     <>
//       <Header />
//       <div className="container-fluid mob-pain-winlist">
//         <div className="row">
//           <div className="col-md-12">
//             <div className="mob-pain-winyrs">
//               <img src={mob_paintathonList} alt="Paintathon" width="100%" />
//               <div className="mob-pain-yrs">
//                 <ul>
//                   <li><a><img src={mob_paintathon_2021} alt="" /></a></li>
//                   <li><a><img src={mob_paintathon_2022} alt="" /></a></li>
//                   <li><a ><img src={mob_paintathon_2024} alt="" /></a></li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>

//         <div className="row">
//           <div className="col-md-12">
//             <div className="mob-pain-yrs21">
//               <div className="yer-2024">
//                 <Link to="/mob_painthon_win_list_2024">
//                 <img src={mob_win_24} alt="Paintathon 2024 Background" width="100%" />
//                 </Link>
//               </div>

//               <div className="pain-winlist-box24">
//                 <div className="col-12">
//                   <h1>Mega Winner</h1>
//                   <div className="mega-win">
//                     <img src={mega_winner_24} alt="Mega Winner" />
//                   </div>
//                 </div>

//                 <div className="col-md-12">
//                   <h1>Group A</h1>
//                   <div className="mega-win-container">
//                     <ul>
//                       <li><img src={groupImage1} alt="Group A Winner 1" /></li>
//                       <li><img src={groupImage_2} alt="Group A Winner 2" /></li>
//                       <li><img src={groupImage_3} alt="Group A Winner 3" /></li>
//                     </ul>
//                   </div>
//                 </div>

//                 <div className="col-md-12">
//                   <h1>Group B</h1>
//                   <div className="mega-win-container">
//                     <ul>
//                       <li><img src={winnerImage} alt="Group B Winner 1" /></li>
//                       <li><img src={winnerImage_2} alt="Group B Winner 2" /></li>
//                       <li><img src={winnerImage_4} alt="Group B Winner 3" /></li>
//                     </ul>
//                   </div>
//                 </div>

//                 <div className="col-md-12">
//                   <h1>Group C</h1>
//                   <div className="mega-win-container">
//                     <ul>
//                       <li><img src={groupImage1} alt="Group C Winner 1" /></li>
//                       <li><img src={groupImage_2} alt="Group C Winner 2" /></li>
//                       <li><img src={groupImage_3} alt="Group C Winner 3" /></li>
//                     </ul>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }
