import React, { useEffect, useState } from 'react';
import { Header } from '../../Header/Header';
import { Footer } from '../../appFooter/Footer';
import { fetchWinners, Winner } from '../../../services/conanContest/conanService';
import { mob_conan_Win_List } from '../../../constants/iconImageConstant';

export function MobconanWinList() {
  const [winners, setWinners] = useState<Winner[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getWinners = async () => {
      try {
        const data = await fetchWinners();
        setWinners(data);
      } catch (err) {
        setError('Failed to fetch winners');
      } finally {
        setLoading(false);
      }
    };

    getWinners();
  }, []);

  const getGroupLabel = (ageGroup: string) => {
    switch (ageGroup) {
      case 'GROUP A(0-10)':
        return 'Group A';
      case 'GROUP B(11-15)':
        return 'Group B';
      case 'GROUP C(16-20)':
        return 'Group C';
      default:
        return '';
    }
  };

  const groupedWinners = winners.reduce((acc, winner) => {
    const group = getGroupLabel(winner.age);
    if (!acc[group]) acc[group] = [];
    acc[group].push(winner);
    return acc;
  }, {} as Record<string, Winner[]>);

  return (
    <>
      <Header />
      <div className="container-fluid mob-conanbg">
        <div className="row">
          <div className="col-md-12">
            <div className="mob-pain-nxtpg-cont">
              <img src={mob_conan_Win_List} alt="Conan" width="100%" />
              {loading ? (
                <div>Loading...</div>
              ) : error ? (
                <div>{error}</div>
              ) : Object.keys(groupedWinners).length > 0 ? (
                <>
                  {/* Render Group A */}
                  {groupedWinners['Group A'] && groupedWinners['Group A'].length > 0 && (
                    <div className="mob-conan-win-box">
                      <div className="gp-tit">Group A</div>
                      {groupedWinners['Group A'].map((winner) => (
                        <div key={winner.id}>
                          <div className="win-candidatenm">{winner.name}</div>
                          <div className="conan-gpa">
                            <img
                              src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
                              alt={`${winner.name}'s photo`}
                              width="100%"
                            />
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      ))}
                    </div>
                  )}
                  
                  {/* Render Other Groups */}
                  <div className="mob-gps">
                    <div className="col-10 offset-1">
                      <ul>
                        {/* Render Group B */}
                        {groupedWinners['Group B'] && groupedWinners['Group B'].length > 0 && (
                          <li>
                            <div className="gp-tit">Group B</div>
                            {groupedWinners['Group B'].map((winner) => (
                              <div key={winner.id}>
                                <div className="win-candidatenm">{winner.name}</div>
                                <div className="conan-gpa">
                                  <img
                                    src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
                                    alt={`${winner.name}'s photo`}
                                    width="100%"
                                  />
                                </div>
                                <div className="clearfix"></div>
                              </div>
                            ))}
                          </li>
                        )}
                        
                        {/* Render Group C */}
                        {groupedWinners['Group C'] && groupedWinners['Group C'].length > 0 && (
                          <li>
                            <div className="gp-tit">Group C</div>
                            {groupedWinners['Group C'].map((winner) => (
                              <div key={winner.id}>
                                <div className="win-candidatenm">{winner.name}</div>
                                <div className="conan-gpa">
                                  <img
                                    src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
                                    alt={`${winner.name}'s photo`}
                                    width="100%"
                                  />
                                </div>
                                <div className="clearfix"></div>
                              </div>
                            ))}
                          </li>
                        )}
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <div>No winners available.</div>
              )}
            </div>
          </div>
        </div>
        <div className="clearfix"></div>
      </div>
      <Footer />
    </>
  );
}









































































































// added new dynamic code with static design  ....04/09/2024 
// import React, { useEffect, useState } from 'react';
// import { Header } from '../../Header/Header';
// import { Footer } from '../../appFooter/Footer';
// import { mob_conan_Win_List } from '../../../constants/iconImageConstant';

// export function MobconanWinList() {
 
//   return (
//     <>
//     <Header />
//     <div className="container-fluid mob-conanbg">
//       <div className="row">
//         <div className="col-md-12">
//           <div className="mob-pain-nxtpg-cont">
//             <img src={mob_conan_Win_List} alt="Conan" width="100%" />
//             <div className="mob-conan-win-box">
//               <div className="gp-tit">Group A</div>
//               <div className="win-candidatenm">Madupathi Sanvi</div>
//               <div className="clearfix"></div>
//               <div className="conan-gpa">
//                 <img src="images/4.jpg" alt="Conan" width="100%" />
//               </div>
//               <div className="clearfix"></div>
//             </div>
//             <div className="mob-gps">
//               <div className="col-10 offset-1">
//                 <ul>
//                   <li>
//                     <div className="gp-tit">Group B</div>
//                     <div className="win-candidatenm">Shiva Kumar</div>
//                     <div className="conan-gpa">
//                       <img src="images/3.jpg" alt="Conan" width="100%" />
//                     </div>
//                   </li>
//                   <li>
//                     <div className="gp-tit">Group C</div>
//                     <div className="win-candidatenm">Shiva Sai</div>
//                     <div className="conan-gpa">
//                       <img src="images/2.jpg" alt="Conan" width="100%" />
//                     </div>
//                   </li>
//                 </ul>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="clearfix"></div>
//     </div>
//     <Footer />
//   </>
//   );
// }

