// import React, { useEffect, useState } from 'react';
// import { Header } from '../Header/Header';
// import { Footer } from '../appFooter/Footer';
// import { fetchWinners, Winner } from '../../services/conanContest/conanService';
// import { conanwin } from '../../constants/iconImageConstant';

// export function CanonWinList() {
//   const [winners, setWinners] = useState<Winner[]>([]);
//   const [loading, setLoading] = useState<boolean>(true);
//   const [error, setError] = useState<string | null>(null);

//   useEffect(() => {
//     const getWinners = async () => {
//       try {
//         const data = await fetchWinners();
//         setWinners(data);
//       } catch (err) {
//         setError('Failed to fetch winners');
//       } finally {
//         setLoading(false);
//       }
//     };

//     getWinners();
//   }, []);

//   const getGroupLabel = (ageGroup: string) => {
//     switch (ageGroup) {
//       case 'GROUP A(0-10)':
//         return 'Group - A';
//       case 'GROUP B(11-15)':
//         return 'Group - B';
//       case 'GROUP C(16-20)':
//         return 'Group - C';
//       default:
//         return '';
//     }
//   };

//   return (
//     <div className="winlist-container">
//       <Header />
//       <div className="main-content">
//         <div className="container-fluid">
//           <div className="row">
//             <div className="conan-bgs">
//               <img src={conanwin} alt="Conan Win" width="100%" />
//               <div className="col-md-6 offset-3">
//                 <div className="conan-winlistbg">
//                   {loading ? (
//                     <div>Loading...</div>
//                   ) : error ? (
//                     <div>{error}</div>
//                   ) : winners.length > 0 ? (
//                     <>
//                       <div className="col-md-6 offset-3 pl-5">
//                         <div className="gp01">
//                           <h3>{getGroupLabel(winners[0].age)}</h3>
//                           <div className="win-name">{winners[0].name}</div>
//                           <div className="win-pic">
//                             <img
//                               src={`https://assets.etvbalbharat.com/conan_contest/${winners[0].photo}`}
//                               alt="winners"
//                               className="img-res"
//                             />
//                           </div>
//                         </div>
//                       </div>
//                       <div className="col-md-10 offset-1">
//                         <div className="row">
//                           {winners.slice(1).map((winner) => (
//                             <div key={winner.id} className="col-md-6">
//                               <div className="grsbc mt-xl">
//                                 <h3>{getGroupLabel(winner.age)}</h3>
//                                 <div className="win-name">{winner.name}</div>
//                                 <div className="win-pic">
//                                   <img
//                                     src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
//                                     alt="winners"
//                                     className="img-res"
//                                   />
//                                 </div>
//                               </div>
//                             </div>
//                           ))}
//                         </div>
//                       </div>
//                     </>
//                   ) : (
//                     <div>No winners available.</div>
//                   )}
//                 </div>
//               </div>
//               <div className="clearfix"></div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </div>
//   );
// }



// added new code ...................
import React, { useEffect, useState } from 'react';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';
import { fetchWinners, Winner } from '../../services/conanContest/conanService';
import { conanwin } from '../../constants/iconImageConstant';

export function CanonWinList() {
  const [winners, setWinners] = useState<Winner[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const getWinners = async () => {
      try {
        const data = await fetchWinners();
        setWinners(data);
      } catch (err) {
        setError('Failed to fetch winners');
      } finally {
        setLoading(false);
      }
    };

    getWinners();
  }, []);

  const getGroupLabel = (ageGroup: string) => {
    switch (ageGroup) {
      case 'GROUP A(0-10)':
        return 'Group - A';
      case 'GROUP B(11-15)':
        return 'Group - B';
      case 'GROUP C(16-20)':
        return 'Group - C';
      default:
        return '';
    }
  };

  return (
    <div className="winlist-container">
      <Header />
      <div className="main-content">
        <div className="container-fluid">
          <div className="row">
            {/* Full-width background image */}
            <div className="col-12 conan-bgs p-0">
              <img src={conanwin} alt="Conan Win" className="img-fluid" width="100%" />
            </div>

            {/* Main content with winners list */}
            <div className="col-md-6 offset-md-3 mt-4">
              <div className="conan-winlistbg p-4">
                {loading ? (
                  <div>Loading...</div>
                ) : error ? (
                  <div>{error}</div>
                ) : winners.length > 0 ? (
                  <>
                    {/* Group A Winner */}
                    <div className="text-center mb-4">
                      <h3>{getGroupLabel(winners[0].age)}</h3>
                      <div className="win-name">{winners[0].name}</div>
                      <div className="win-pic">
                        <img
                          src={`https://assets.etvbalbharat.com/conan_contest/${winners[0].photo}`}
                          alt="Winner"
                          className="img-fluid"
                        />
                      </div>
                    </div>

                    {/* Other Winners */}
                    <div className="row">
                      {winners.slice(1).map((winner) => (
                        <div key={winner.id} className="col-md-6 text-center mb-4">
                          <h3>{getGroupLabel(winner.age)}</h3>
                          <div className="win-name">{winner.name}</div>
                          <div className="win-pic">
                            <img
                              src={`https://assets.etvbalbharat.com/conan_contest/${winner.photo}`}
                              alt="Winner"
                              className="img-fluid"
                            />
                          </div>
                        </div>
                      ))}
                    </div>
                  </>
                ) : (
                  <div>No winners available.</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
