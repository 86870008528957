import React from 'react';
import { Link } from 'react-router-dom';
import { ROUTER_URL_CONSTANT } from '../../constants/routerUrlConstant';
import { Header } from '../Header/Header';
import { Footer } from '../appFooter/Footer';
import { conanyear, conanbtn } from '../../constants/iconImageConstant';


export function CanonYear() {
  return (
    <>
    <Header />
      <div className="container-fluid">
        <div className="row">
          <div className="conan-bgs">
            <img src={conanyear} alt="conan" width="60%" />
            <div className="conan-btn">
            <Link to={ROUTER_URL_CONSTANT.CANON_WINNER_LIST} className="link-next">
                <img src={conanbtn} alt="canon-btn" width="160px" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}


