import React from 'react';
import { Link } from 'react-router-dom'; 
import { ROUTER_URL_CONSTANT } from '../../../constants/routerUrlConstant'; 
import { Header } from '../../Header/Header'; 
import { Footer } from '../../appFooter/Footer'; 
import { mob_nextPage, mob_nextButton  } from '../../../constants/iconImageConstant';
import "../../../assets/css/mob-event.css";

export function MobPaintathonNextPage() {
  return (
    <>
      <Header />
      <div className="container-fluid mob-painnextbg">
        <div className="col-md-12">
          <div className="mob-pain-nxtpg-cont">
            <img src={mob_nextPage} alt="paintathon" />
            
            <div className="mob-pain-nxtpgbt">
              <Link to={ROUTER_URL_CONSTANT.MOB_PAINT_WIN_LIST_2021}>
                <img src={mob_nextButton } alt="Next Page" />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
