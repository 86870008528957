import React from 'react';
import { useHistory } from 'react-router-dom';
import { mob_Painthon, mob_Conan } from '../../../constants/iconImageConstant';
import { Header } from '../../Header/Header';
import { Footer } from '../../appFooter/Footer';

export function MobEventPage() {
  const history = useHistory();

  const handleNavigate = (path: string) => {
    history.push(path);
  };

  return (
    <div>
      <Header />
      <main role="main" className="container">
      <div className="col-md-12 mt-5 mb-3">
          <div className="col-12 mb-3">
            <img
              src={mob_Painthon}
              alt="paintathon"
              className="img-fluid clickable-image"
              onClick={() => handleNavigate('/mobpaintathon')}
            />
          </div>
          <div className="col-12">
            <img
              src={mob_Conan}
              alt="conanImage"
              className="img-fluid clickable-image"
              onClick={() => handleNavigate('/mob_canon_winner_year')}
            />
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};



