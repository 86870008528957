import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { API_URL_CONSTANTS } from '../../../constants/apiUrlConstants';
import {
  mob_paintathonList,
  mob_paintathon_2021,
  mob_paintathon_2022,
  mob_paintathon_2024,
  mob_win_23
} from '../../../constants/iconImageConstant';
import { Header } from '../../Header/Header';
import { Footer } from '../../appFooter/Footer';

// Define the type for session details
interface SessionDetail {
  id: string;
  name: string;
  image: string;
}

export function MobPainwinList2022() {
  const [response, setResponse] = useState<SessionDetail[]>([]);
  const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.PAINTATHON_RESULT;

  useEffect(() => {
    fetchPaintathonResult('2022'); 
  }, []);

  const fetchPaintathonResult = async (year: string) => {
    try {
      const res = await axios.post(
        url,
        { params: { year } },
        { headers: { 'Content-Type': 'application/json' } }
      );

      if (Array.isArray(res.data)) {
        setResponse(res.data);
      } else if (res.data && Array.isArray(res.data.results)) {
        setResponse(res.data.results);
      } else {
        setResponse([]);
        console.warn('Unexpected response format:', res.data);
      }
    } catch (error) {
      console.log('Error fetching data:', error);
    }
  };

  // Extract Group A and Group B from the response
  const groupA = response.slice(0, 3);  // Adjust slice indices if necessary
  const groupB = response.slice(3, 6);  // Adjust slice indices if necessary

  // Component to render image groups
  const ImageGroup = ({ title, group }: { title: string; group: SessionDetail[] }) => (
    <>
      <h1>{title}</h1>
      <div className="win-container">
        <ul>
          {group.map((item) => (
            <li key={item.id}>
              <img src={`https://assets.etvbalbharat.com/${item.image}`} alt={item.name} />
            </li>
          ))}
        </ul>
      </div>
    </>
  );

  return (
    <>
      <Header />
      <div className="container-fluid mob-pain-winlist">
        <div className="col-md-12">
          <div className="mob-pain-winyrs">
            <img src={mob_paintathonList} alt="Paintathon List" width="100%" />
            <div className="mob-pain-yrs">
              <ul>
                <li>
                  <Link to="/mob_painthon_win_list_2021">
                    <img src={mob_paintathon_2021} alt="Paintathon 2021" />
                  </Link>
                </li>
                <li>
                  <Link to="/mob_painthon_win_list_2022">
                    <img src={mob_paintathon_2022} alt="Paintathon 2022" />
                  </Link>
                </li>
                <li>
                  <Link to="/mob_painthon_win_list_2024">
                    <img src={mob_paintathon_2024} alt="Paintathon 2024" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="col-md-12">
          <div className="mob-pain-yrs21">
            <div className="yer-2023">
              <img src={mob_win_23} alt="Paintathon 2023 Background" width="100%" />
            </div>
            <div className="pain-winlist-box">
              {response.length > 0 ? (
                <>
                  {groupA.length > 0 && <ImageGroup title="Group A" group={groupA} />}
                  {groupB.length > 0 && <ImageGroup title="Group B" group={groupB} />}
                </>
              ) : (
                <p>No results available for the selected year.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
























































// import React from 'react';
// import { Link } from 'react-router-dom';
// import {mob_win_23,mob_paintathonList, mob_paintathon_2021, mob_paintathon_2023,
//   mob_paintathon_2024,winnerImage_2,winnerImage_4,groupImage1,groupImage_2,groupImage_3,winnerImage
// } from '../../../constants/iconImageConstant';
// import { Header } from '../../Header/Header'; 
// import { Footer } from '../../appFooter/Footer'; 

// export function MobPainwinList2023() {
//   return (
//     <>
//       <Header />
//       <div className="container-fluid mob-pain-winlist">
//         <div className="col-md-12">
//           <div className="mob-pain-winyrs">
//             <img src={mob_paintathonList} alt="Paintathon List" width="100%" />
//             <div className="mob-pain-yrs">
//               <ul>
//                 <li>
//                   <Link to="/mob-pain-winlist-2021">
//                     <img src={mob_paintathon_2021} alt="Paintathon 2021" />
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/mob-pain-winlist-2023">
//                     <img src={mob_paintathon_2023} alt="Paintathon 2023" />
//                   </Link>
//                 </li>
//                 <li>
//                   <Link to="/mob_painthon_win_list_2024">
//                     <img src={mob_paintathon_2024} alt="Paintathon 2024" />
//                   </Link>
//                 </li>
//               </ul>
//             </div>
//           </div>
//         </div>

//         {/* 2023 Results */}
//         <div className="col-md-12">
//           <div className="mob-pain-yrs21">
//             <div className="yer-2023">
//               <img src={mob_win_23} alt="Paintathon 2023 Background" width="100%" />
//             </div>
//             <div className="pain-winlist-box">
//               <div className="col-md-12">
//                 <h1>Group A</h1>
//                 <div className="win-container">
//                   <ul>
//                     <li><img src ={winnerImage} alt="Group A Winner 1" /></li>
//                     <li><img src={winnerImage_4} alt="Group A Winner 2" /></li>
//                     <li><img src={winnerImage_2} alt="Group A Winner 3" /></li>
//                   </ul>
//                 </div>
//               </div>

//               <div className="col-md-12">
//                 <h1>Group B</h1>
//                 <div className="win-container">
//                   <ul>
//                     <li><img src={groupImage1} alt="Group B Winner 1" /></li>
//                     <li><img src={groupImage_2} alt="Group B Winner 2" /></li>
//                     <li><img src={groupImage_3} alt="Group B Winner 3" /></li>
//                   </ul>
//                 </div>
//               </div>

//               <div className="col-md-12">
//                 <h1>Group C</h1>
//                 <div className="win-container">
//                   <ul>
//                     <li><img src={winnerImage} alt="Group C Winner 1" /></li>
//                     <li><img src={groupImage1} alt="Group C Winner 2" /></li>
//                     <li><img src={groupImage_2} alt="Group C Winner 3" /></li>
//                   </ul>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <Footer />
//     </>
//   );
// }
