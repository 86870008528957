// added new code  04/09/2024
import React from 'react';
import { API_URL_CONSTANTS } from '../../constants/apiUrlConstants';

const API_URL = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.CONAN_CONTEST;

export interface Winner {
  id: string;
  name: string;
  age: string; 
  photo: string;
 
}

export const fetchWinners = async (): Promise<Winner[]> => {
  try {
    const response = await fetch(API_URL, {
      method: 'GET',
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    return data as Winner[];
  } catch (error) {
    console.error('Error fetching winners:', error);
    throw error;
  }
};



// conanContestActions.ts

// import { getReq } from '../apiCall'; 
// import { actionTypes } from '../../store/getpage';
// import { API_URL_CONSTANTS } from '../../constants/apiUrlConstants';

// export function getpage() {
//   const url = API_URL_CONSTANTS.BASE_URL + API_URL_CONSTANTS.CONAN_CONTEST;
//   return (dispatch: any) => {
//     dispatch({ type: actionTypes.GET_PAGE_ACTION });
//     getReq(url)
//       .then((response: any) => {
//         if (response.status === 200 && response.data.status === 1) {
//           dispatch({
//             type: actionTypes.GET_PAGE_SUCCESS_ACTION,
//             payload: { data: response.data.data },
//           });
//         } else if (response.data.status === 0) {
//           dispatch({
//             type: actionTypes.GET_PAGE_FAILURE_ACTION,
//             payload: { error: response.data.errorDetails[0] },
//           });
//         }
//       })
//       .catch((error: any) => {
//         dispatch({
//           type: actionTypes.GET_PAGE_FAILURE_ACTION,
//           payload: { error: 'Failed to fetch data' },
//         });
//       });
//   };
// }




 